import IMG from './img/*.jpg'
const allImg = IMG

let colors = ['#18f252', '#18e7f2', '#f2182a', '#1861f2', '#5218f2']

const imgBack = [...document.querySelectorAll('.av_wrapper img')]
const avSection = [...document.querySelectorAll('.av_section')]

imgBack.forEach((img, index) => {
  img.src = `${allImg[index + 1]}`
})

avSection.forEach((element, index) => {
  element.addEventListener('mouseenter', () => {
    const linear = element.children[1]
    linear.setAttribute(`style`, `background: linear-gradient(0deg, ${colors[index]}, rgba(57, 62, 70, 0) 100%)`)
  })
})

avSection.forEach((element, index) => {
  element.addEventListener('mouseleave', () => {
    const linear = element.children[1]
    linear.setAttribute(`style`, `background: linear-gradient(0deg, rgba(4, 18, 40, 1) 0%, rgba(57, 62, 70, 0) 100%)`)
  })
})

const loader = document.querySelector('.loader')
const titles = document.querySelectorAll('h2')
const descriptions = document.querySelectorAll('p')
const avSections = [...document.querySelectorAll('.av_section')]
const videos = [...document.getElementsByTagName('video')]
const name = [...document.getElementsByTagName('h2')]

avSections.forEach((avSection, index) => {
  avSection.addEventListener('click', e => {
    const img = avSection.children[0]

    let isActive = e.target.classList.contains('active')
    for (let i = 0; i < avSections.length; i++) {
      avSections[i].classList.remove('active')
    }

    if (isActive) {
      for (let i = 0; i < avSections.length; i++) {
        avSections[i].classList.remove('expand')
        setTimeout(() => {
          videos[index].volume = 0.1
          videos[index].pause()
          videos[index].style.opacity = 0
        }, 600)
        img.style.opacity = 1
        name[index].style.opacity = 1
      }
      return
    }
    avSections[index].classList.add('active')

    if (videos[index] && name[index]) {
      setTimeout(() => {
        videos[index].volume = 0.1
        videos[index].play()
        videos[index].style.opacity = 1
      }, 1200)
      img.style.opacity = 0
      name[index].style.opacity = 0
    }
  })
})

window.addEventListener('DOMContentLoaded', event => {
  setTimeout(() => {
    loader.style.opacity = 0
    titles.forEach(title => {
      title.classList.add('active')
    })
    descriptions.forEach(description => {
      description.classList.add('active')
    })
  }, 2000)
})
